import React, { useEffect, useState } from "react";

const Feature = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
  });

  const color= localStorage.getItem("color");
  useEffect(()=>{
   
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)`})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)`})
  },[color]);  

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            <img src={`${process.env.PUBLIC_URL}/assets/images/222.png`} className="img-fluid" alt="" />
          </div>
          <div className="offset-lg-4 col-lg-8">
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                Buhara <span> Özellikleri</span>
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/1.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Kullanıcı Dostu</h3>
                    </div>
                    <div>
                      <p>
                        Kullanıcı dostu mobil tasarımımız ile sizin için kullanımı kolaylaştırdık.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Size Özel Yemek Tarifleri</h3>
                    </div>
                    <div>
                      <p>
                        YÜzlerce yemek tarifini uygulamamızda keşfet.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/5.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Barkod Okuma</h3>
                    </div>
                    <div>
                      <p>
                        Ürünlerimizde bulunan barkodu okutarak uygulamamız üzerinden ürün hakkında bilgi alabilirsiniz.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 sm-m-top">
                <ul className="feature-style">
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Favori Tarifler</h3>
                    </div>
                    <div>
                      <p>
                       Tarifleri faoriye ekleyerek tariflerimize daha kolay ulaşabilirsiniz.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Alışveriş Listesi</h3>
                    </div>
                    <div>
                      <p>
                        Tariflerden veya ürünlerimiz bölümünden ürünleri veya ürünlerimizi alışveriş sepetine ekleyebilirsiniz..{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="feature-icon">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`} alt="icon" />
                    </div>
                    <div className="feature-subtitle">
                      <h3>Tarif-Ürün Besin Değerleri</h3>
                    </div>
                    <div>
                      <p>
                        Tariflerde ve ürünlerdeki besin değerlerini uygulamamız üzerinde görüntüleyebilirsiniz.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
