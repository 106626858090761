import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
import "react-modal-video/scss/modal-video.scss";
import Tilt from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import ModalVideo from "react-modal-video";
import {
 
  Modal,
  ModalHeader
} from "reactstrap";


const HomeTwo = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  /*For changing background image by changing the color*/
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/home2/home2-slider.png)`
  });

  const [subscribemodal, setSubscribemodal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(false);
    }, 2000);
  }, []);

  const color= localStorage.getItem("color");

  useEffect(()=>{
   
    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/home2/home2-slider.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/Homev2.png)`
	})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev2.png)`
	})
  },[color]);
  
  /* For Open video modal */
  const [isOpen, setIsOpen] = useState(false);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.add("home-style-two");
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-two"/>

      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-checkbox-marked-circle"></i>
              </div>
            </div>

            <h1 className="text-red">
                      
                      <span className="f-bold f-color text-red">Çok Yakında</span>

                     
                    </h1>

           {/*  <a target="_blank" href="https://apps.apple.com/us/app/buhara/id6463096365" >
                      <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.buharabaharatmobil">
                      <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                      />
                    </a>*/}
<a target="_blank" href="" >
                      <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a target="_blank" href="h">
                      <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                      />
                    </a>
                    
           
          </div>
        </div>
      </Modal>

      {/* Home Two Section Start */}
      <section id="home" className="home home-two vertical-scrolling" style={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="home-contain">
                <div className="text-white">
                  <div className="contain">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home2/sapka.png`} alt="logo" />
                   
                    <h1 className="text-white">
                      B<span className="f-bold ">U</span>H
                      <span className="f-bold ">A</span>
                      R<span className="f-bold ">A</span>
                      <span className="f-bold f-color text-white"> App</span>
                    </h1>
                    <p className="slide-cap-desc">
                      Tarifini bul, ürünleri alışveriş listesine ekle ve Buhara ürünlerini keşfet
                    </p>
                    <a rel="noreferrer" target="_blank" href="https://apps.apple.com/us/app/buhara/id6463096365" >
                      <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.buharabaharatmobil">
                      <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                      />
                    </a>
                  </div>
                  <div className="play-button">
                    <ModalVideo
                      channel="custom"
                      isOpen={isOpen}
                      url='https://app.buhara.com/tanitim.mp4'
                      height={600}
                      width={800}
                      onClose={() => setIsOpen(false)}
                    />
                    <a href="javascript" onClick={(e)=>{e.preventDefault()}} className="popup-vimeo animated-circle">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/home2/play-button.png`}
                        alt="play-button"
                        onClick={() => setIsOpen(true)}
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5">
              <div className="home-right">
                <Tilt
                  options={{ perspective: 110, speed: 400, max: 1.2, scale: 1 }}
                >
                  <img
                    data-tilt
                    data-tilt-perspective="110"
                    data-tilt-speed="400"
                    data-tilt-max="1.2"
                    src={`${process.env.PUBLIC_URL}/assets/images/home2/slider-caption.png`}
                    className="img-fluid"
                    alt="slider-caption"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Two Section End */}

      {/* About Component*/}
      <About />

      {/*Feature Component*/}
      <Feature />

      {/*ScreenShot Component*/}
      <ScreenShot />

      {/*Team Component*/}


      {/*Blog Component*/}
      <Blog />

      {/*Price Component*/}
     

      {/*Testimonial Component*/}
     

      {/*Contact Component*/}
      <Contact />

      {/*Subscription Component*/}
      <Subscribe />

      {/*Footer Component*/}
      <Footer />
    </div>
  );
};

export default HomeTwo;
