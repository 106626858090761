import React, { useEffect } from "react";

const Footer = () => {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">
                  <div>
                    <ul className="footer-style">
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>
                          Fevzi Çakmak mh, Aslım Cd. No:53/A-B D:C, 42050 Karatay/Konya
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>(0332) 342 36 80</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e)=>{e.preventDefault()}}>bilgi@buhara.com</a>
                        </div>
                      </li>
                      <li>
                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3147.5286031657693!2d32.55414007574676!3d37.918079703892424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fde0028003f%3A0x9a34903fadff627e!2sBuhara%20Baharat!5e0!3m2!1str!2str!4v1694408173368!5m2!1str!2str"
                  allowFullScreen
                  className="md-height"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>2024 copyright by Stechome</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Merhaba! Size Nasıl Yardımcı Olabilirim?"
        logged_out_greeting="Merhaba! Size Nasıl Yardımcı Olabilirim?"
      ></div>
    </div>
  );
};

export default Footer;
